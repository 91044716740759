body {
  display: block;
  width: 100%;
  height: 100vh;
}

#root {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}

li.recharts-legend-item {
  padding-bottom: 4px;
}

.specialIconContainer div {
  padding-top: 0 !important;
}

.specialIcon {
  height: 150px !important;
}

a {
  text-decoration: none;
}

h1,
h5 {
  font-family: "Gloria Hallelujah", cursive !important;
  font-weight: 500;
}

header a {
  font-family: "Gloria Hallelujah", cursive !important;
  font-weight: 500;
  color: #fff;
}

p,
button {
  font-family: "Roboto", sans-serif !important;
}

.MuiInput-underline:before {
  border-bottom: 0 !important;
}

footer p a {
  font-family: "Gloria Hallelujah", cursive !important;
  font-weight: 500;
  color: #2ec4b6;
}

footer p a:hover {
  color: #218380;
}

g > text {
  fill: black;
  font-weight: 500;
}
